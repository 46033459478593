import React, { useEffect, useRef } from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: '5rem',
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    [theme.breakpoints.up('md')]: {
    marginBottom: '15rem',
    }
  },
  subline: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  text: {
    marginTop:"0.5rem",
    maxWidth:"55rem",
    textAlign:"center",
  },
  content: {
    alignItems: 'center',
    paddingTop: '3rem',
  },
}));

function Section(props) {
  const classes = useStyles();

  const element = useRef();
  const trigger = useRef();

    React.useEffect(() => {
      gsap.fromTo(element.current, {
        skewY:-3,
        }, {
        skewY:0,
        scrollTrigger: {
          trigger: element.current,
          start: "top bottom",
          end: "top center",
          scrub: 0.5,
        }
      });
    });



  return (
    <Container ref={element} className={classes.root}>
      <Typography  variant="h6" className={classes.subline}>
        {props.subline}
      </Typography>
      <Typography variant="h2" className={classes.headline}>
        {props.headline}
      </Typography>
      <Typography variant="subtitle1" className={classes.text}>
        {props.text}
      </Typography>
      <Grid container className={classes.content}>
        {React.Children.toArray(props.children).map((child) => {
          switch (props.columns) {
            case 2:
              return (
                <Grid item xs={12} sm={6}>
                  {child}
                </Grid>
              );
            default:
              return (
                <Grid item xs={12}>
                  {child}
                </Grid>
              );
          }
        })}
      </Grid>
    </Container>
  );
}

export default Section;
