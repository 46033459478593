import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    color: theme.palette.primary.main,
    backgroundColor:"transparent"
  },
}));

function Module(props) {
  const classes = useStyles();

  function handleChange(_event, isExpanded) {
    props.onExpand(isExpanded);
  };

  return (
    <Accordion 
      elevation={0}
      className={classes.root}
      expanded={props.isExpanded}
      onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMore color="secondary" fontSize="large" />}>
        <Typography variant="h3">{props.heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{props.children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default Module;
