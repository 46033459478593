import React, { useContext, useEffect } from 'react';

import { connect } from 'react-redux';
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/whiteTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Paper } from '@material-ui/core';

import Ad from '../../components/Ad/Ad';
import Header from '../../components/Header/Header';
import Paragraph from '../../components/Paragraph/Paragraph';
import Ribbon from '../../components/Ribbon/Ribbon';
import SectionSplit from '../../components/Section/Section-split';
import SectionFull from '../../components/Section/SectionFull';
import SectionFullAccordion from '../../components/Section/SectionFullAccordion';
import Footer from '../../components/Footer/Footer';
import Team from './Sections/TeamSection/Team';
import InteractiveImage from '../../components/InteractiveImage/InteractiveImage';
import AccordionSlider from './Sections/AccordionSlider/AccordionSlider';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  video_embed:{
    position: 'relative',
    display: 'block',
    maxWidth: '1000px',
    width:"100%",
    overflow: 'hidden',
    paddingLeft:"1rem",
    paddingRight:"1rem",

    '& > iframe':{
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '0' ,
    },

    '&::before':{
    display: 'block',
    content: `''`,
    paddingTop: '56.25%',
  },
  }
}));

const pageType = 'Interior';

function InteriorPage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);

  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections)) {
      loadSections(language);
    }
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <Paper>
        <Ribbon adjustOnScroll={true} />
        {header && language in header && (
          <Header
            headline={header[language].headline}
            subline={header[language].subline}
          />
        )}
      </Paper>
      <Box className={classes.root}>
        {header && language in header && (
          <Paragraph>{header[language].text}</Paragraph>
        )}

        {sections &&
          language in sections &&
          sections[language].map((section) => {
            switch (section.type) {
              case 'Ad':
                return (
                  <Ad
                    headline={section.headline}
                    subline={section.subline}
                    text={section.text}
                    link={section.link}
                    image={section.image}
                  />
                );
              case 'Eyecatcher':
                return (
                  <InteractiveImage
                    headline={section.headline}
                    subline={section.subline}
                    image={section.image.url}
                    eyecatchers={section.children}
                  />
                );
              case 'Modules':
                return (
                  <SectionFullAccordion
                    headline={section.headline}
                    subline={section.subline}
                  >
                    <AccordionSlider modules={section.children} />
                  </SectionFullAccordion>
                );
              case 'Staff':
                return (
                  <SectionSplit
                    headline={section.headline}
                    subline={section.subline}
                    text={section.text}
                  >
                    <figure>
                      <img
                        src={section.image.url}
                        className={classes.image}
                        alt={section.headline}
                      />
                    </figure>
                  </SectionSplit>
                );
              case 'Team':
                return (
                  <SectionFull headline={section.headline}>
                    <Team members={section.children} />
                  </SectionFull>
                );
              case 'Video':
                return (
                  <SectionFull
                    headline={section.headline}
                    subline={section.subline}
                  >
                    <div
                    className={classes.video_embed}
                    >
                    <iframe
                      title={section.subline}
                      src={section.link.to}
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    </div>
                  </SectionFull>
                );
              default:
                return <></>;
            }
          })}
      </Box>

      <Footer />
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language)),
});

const mapStateToProps = (state) => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InteriorPage);
