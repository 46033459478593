import * as types from '../types';


export const fetchFlipbookScenes = () => {
  return {
    type: types.FETCH_FLIPBOOK_SCENES,
    meta: {
      type: 'asset',
    }
  }
}
