import React, { useContext, useEffect } from 'react';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/whiteTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Container, Paper, Typography, Grid } from '@material-ui/core';

import Header from '../../components/Header/Header';
import Ribbon from '../../components/Ribbon/Ribbon';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
impressRoot:{
  maxWidth:"900px",
  margin:"0 auto",
  marginTop:"2.5rem",
  marginBottom:"5rem",

  [theme.breakpoints.up('md')]: {
    marginTop:"5rem",
    marginBottom:"10rem",
  },

  "& h3":{
    marginBottom:".5rem",
    marginTop:"2rem",
  },
  "& p+p":{
    marginBottom:"1.5rem"
  },
  "& b":{
    marginTop:"1rem",
    display:"block",
  },
  "& a":{
    color: theme.palette.secondary.main,
  },
},

partnerLogo:{
  display:"flex",
  marginTop:"2rem",
  flexDirection:"column",

[theme.breakpoints.up('md')]: {
  flexDirection:"row"
},

  "& a":{
    display:"block",
    marginRight:"2rem"
  },
  "& a img":{
    width:"10rem",
    height:"auto"
  }
}

}));

const pageType = "Impressum";

function ImpressumPage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);

  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0}>
      <Ribbon adjustOnScroll={true}/>
        {header && language in header &&
        <Header
          headline = {header[language].headline}
          subline = {header[language].subline}
        />}
      </Paper>
      <Container>
        <Grid container className={classes.impressRoot}>
          <Grid item xs={12}>       
            {sections && language in sections && sections[language].map((section) => {
              switch(section.type){
                case "Impressum": return (
                  <>
                    <Typography variant="h3">
                      {section.headline}
                    </Typography>
                    {section.text.split("\n").map((paragraph) => (
                      <Typography variant="body2">
                        {paragraph}
                      </Typography>
                    ))}
                  </>
                )
                case "Partner": return (
                  <>
                    <Typography variant="h3">
                      {section.headline}
                    </Typography>
                    <div className={classes.partnerLogo}>
                      {section.children.map((child) => (
                        <a 
                          target = "_blank"
                          href = {child.subline}>
                          <img 
                            src = {child.image.url}
                            alt = {child.headline}/>
                        </a>))}
                    </div>
                  </>
                )
                default: return (<></>)            
              }
            })}   
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
});

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpressumPage);
