import React from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TeamMember from './TeamMember';

const useStyles = makeStyles((theme) => ({
  root: {
    justify: 'space-evenly',
    width: '100%',
  },
}));

function Team({members}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {members.length && members.map((member) => (
        <Grid item xs={12} md={4} lg={2}>
          <TeamMember
            name = {member.headline}
            image = {member.image.url}
            title = {member.subline}
            slogan = {member.text} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Team;
