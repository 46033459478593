import React, { useRef, useState } from 'react';
import Slider from "react-slick";

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Module from './Module';

const useStyles = makeStyles((theme) => ({
  root:{
    maxWidth:"unset",
  },
  imageSlider:{

    "& img":{
      width:"35vw",
      height:"auto",
      display:"block",
      maxWidth:"550px",
      margin:"0 auto",
    },
      [theme.breakpoints.down('md')]: {
        display:"none",
      }
  },
  content:{
    display:"flex",
    alignItems:"flex-end",
    flexDirection:"column"
  },
  inner:{
    maxWidth:"900px",
    width:"40vw",
    [theme.breakpoints.down('md')]: {
      maxWidth:"unset",
      width:"100%",
    }
  }
}));

function AccordionSlider({modules}) {
  const classes = useStyles();
  const sliderRef = useRef();
  const [expandedModule, setExpandedModule] = useState(-1);

  const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        arrows:false,
        fade:true,
        draggable:false,
      };

  const handleExpand = (module) => (isExpanded) => {
    sliderRef.current.slickGoTo(module);
    setExpandedModule(isExpanded ? module : "");
  }

  return (
  <Container className={classes.root}>
    <Grid container spacing={4} >
      <Grid item sm={12} lg={6} className={classes.content} >
        <div className={classes.inner}>
          {modules.map((module, index) => (
            <Module
              heading={module.headline}
              isExpanded={expandedModule === index}
              onExpand={handleExpand(index)}>
                {module.text}
            </Module>
          ))}
        </div>
      </Grid>
      <Grid item sm={12} md={12} lg={6} >
        <Slider ref={sliderRef} className={classes.imageSlider} {...settings}>
          {modules.map((modules) => (
            <div>
              <img src={modules.image.url} alt=""/>
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
  </Container>);
}

export default AccordionSlider;
