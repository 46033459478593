import * as types from '../types';

const zeroPad = (num, places) => String(num).padStart(places, '0');

const currentFrame = (path, index) => (
    require(`../../assets/flipbook/${path}/HCMM${zeroPad(index, 3)}.jpg`));

const handleSceneLoaded = (store, actionType, payload) => {
  let newAction = {
    type: actionType,
    payload: payload
  };
  store.dispatch(newAction);
}

const assetMiddleware = store => next => action => {
  if (!action.meta || action.meta.type !== 'asset') {
    return next(action);
  }

  const scenesCount = 989;
  for (let i = 0; i < scenesCount; ++i) {
    const img = new Image();
    img.src = currentFrame('hcmm_v6_van', i);
    img.onload = () => handleSceneLoaded(
      store,
      types.FETCH_FLIPBOOK_SCENES_WITH_VAN, 
      {[i]: img});
  }
  
  for (let i = 0; i < scenesCount; ++i) {
    const img = new Image();
    img.src = currentFrame('hcmm_v6_noVan', i);
    img.onload = () => handleSceneLoaded(
      store,
      types.FETCH_FLIPBOOK_SCENES_WITHOUT_VAN, 
      {[i]: img});
  }
  
}

export default assetMiddleware;