import React, { useRef } from 'react';
import classNames from "classnames";

import { Typography } from '@material-ui/core';
import Pattern from '../../assets/img/pattern.svg'; // Import using relative path
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);



const useStyles = makeStyles((theme) => ({
card:{
  marginBottom:"10rem",
  width:"100%",
  position:"relative",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",

  [theme.breakpoints.down('sm')]: {
    flexDirection:"column",
    marginBottom:"2.5rem",
  }
},

inner:{
  width:"100%",
  height:"100%",
  overflow:"hidden",
  position:"relative",
  zIndex:"1",
},

patternBG:{
    position:'absolute',
    width:"100%",
    height:"100%",
    left:"-2.5rem",
    top:"2.5rem",
    zIndex:"0",
    backgroundImage:`url(${Pattern})`,
    backgroundRepeat: "repeat round",

    [theme.breakpoints.down('sm')]: {
      left:"0",
      top:"1.5rem",
    },
},

image:{
  width:"400px",
  position:"relative",
  flexShrink:"0",

  [theme.breakpoints.down('sm')]: {
    width:"100%",
    marginRight:"0",
    marginBottom:"3rem",
  },



},
imageWrapper:{
  height:"100%",
  display:"block",

"& img":{
  position:"absolute",
  left:"0",
  top:"0",
  width:"100%",
  height:"100%",
  objectFit:"cover",
  transform:"scale(1.1)",
}
},

circle:{
"& > div" :{
  borderRadius:"50%",
  "& img":{
  transform:"scale(1.2)",
  },
},
"& div + div":{
  left:"-5%",
  top:"-5%",
  width:"110%",
  height:"110%",
},

  "& > div picture::before":{
    content:`''`,
    paddingTop:"100%",
    display:"block",
  },
  [theme.breakpoints.down('sm')]: {
    width:"80%",
  },
},
wide:{
  width:"550px",

  "& > div picture::before":{
    content:`''`,
    paddingTop:"70%",
    display:"block",
  },
},

portrait:{
  "& > div picture::before":{
    content:`''`,
    paddingTop:"120%",
    display:"block",
    [theme.breakpoints.down('sm')]: {
    paddingTop:"70%",
    }
  },
},


headlineTile:{
marginBottom:"1rem",
"&::after":{
  content:`''`,
  backgroundColor: theme.palette.secondary.main,
  textTransform:"uppercase",
  letterSpacing:"0.0.2rem",
  display:"block",
  fontWeight:"bold",
  width:"80px",
  height:"8px",
  marginTop:"0.5rem",

  [theme.breakpoints.down('sm')]: {
    height:"4px"
  },

},
},
textCard:{
opacity:"0.8",
maxWidth:"40rem",
}
}));

function EinsatzPageTile({ name, description, image }) {
  const classes = useStyles();
  const theme = useTheme();
  const elementRef = useRef();
  const imageRef = useRef();
  const patternRef = useRef();


    React.useEffect(() => {
      gsap.fromTo(elementRef.current, {
        skewY:-3,
        }, {
        skewY:0,
        scrollTrigger: {
          trigger: elementRef.current,
          start: "top bottom",
          end: "top center",
          scrub: 1,
        }
      });
      gsap.fromTo(imageRef.current, {
        y:"-10%",
        }, {
        y:"10%",
        scrollTrigger: {
          trigger: elementRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 1,
        }
      });
      gsap.fromTo(patternRef.current, {
        y:"10%",
        }, {
        y:"0%",
        scrollTrigger: {
          trigger: elementRef.current,
          start: "top bottom",
          end: "top top",
          scrub: 1,
        }
      });
    });

  return (
    <div ref={elementRef} className={classes.card}>
      <div className={classNames(classes.image, eval(`classes.${image.format}`))}>
        <div className={classes.inner}>
          <picture ref={imageRef}  className={classNames(classes.imageWrapper)}>
            <img src={image.url} alt=""/>
          </picture>
        </div>
        <div ref={patternRef} className={classes.patternBG}></div>
      </div>
      <div className={classes.content}>
        <Typography className={classes.headlineTile} variant="h3">
          {name}
        </Typography>
        <p className={classNames(classes.textCard,"textCard")}>
          {description}
        </p>
      </div>
    </div>
  );
}

export default EinsatzPageTile;
