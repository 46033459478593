import React, { useEffect, useRef, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";



const useStyle = makeStyles((theme) => ({
  rootIndicator: {
    position: 'fixed',
    top: '50%',
    right: '1.5rem',
    transform:'translateY(-50%)',
    zIndex:"99",
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
  },

  li: {
    width:"0.5rem",
    height:"0.5rem",
    listStyle:"none",
    borderRadius:"50%",
    transformOrigin:"center",
    backgroundColor:"#16E098",
    opacity:"0.2",
    marginBottom:"0.25rem",
    marginTop:"0.25rem",
    transition:"opacity 0.3s,box-shadow 0.3s",

    "&::before":{
      transform:"scale(0)",
      content:`''`,
      width:"1rem",
      height:"1rem",
      position:"relative",
      left:"-0.25rem",
      top:"-0.25rem",
      border:"1px solid #16E098",
      backgroundColor:"transparent",
      display:"block",
      borderRadius:"inherit",
      transformOrigin:"center",
      transition:"all 0.3s",
    },

    "&.active":{
      boxShadow: "0px 0px 12px #16E098",
      opacity:"1",

      "&::before":{
        transform:"scale(1)"
      }
    },
  },

}));

function PageIndicator({nDots, activeSection}) {

  const classes = useStyle();

  return (
    <ul className={classes.rootIndicator}>
      {Array(nDots).fill().map((_, i) =>
        <li
          key={i}
          className={`${classes.li} ${activeSection === i ? "active" : ""}`}
        >
        </li>
      )}
    </ul>
  );
}

export default PageIndicator;
