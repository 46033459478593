import * as types from '../types';
import { gql } from 'graphql-request';


export const fetchSections = (pageType, language) => {
  return {
    type: types.FETCH_SECTIONS,
    language: language,
    meta: {
      type: 'graphql',
      query: gql`{
        pages (where: {type: ${pageType}}, locales:[${language}, de]) {
          type
          sections {
            type
            headline
            subline
            text
            link
            image {
              url
              width
              height
            }
            children {
              ... on Element {
                type
                headline
                subline
                text
                link
                image {
                    url
                    width
                    height
                }
                children {
                  ... on Element {
                    type
                    headline
                  }
                }
              }
            }
          }
        }
      }`
    }
  }
}
