import React, { useRef } from 'react';

import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { ReactComponent as Flag } from '../../../../assets/img/hcf_logo.svg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import gsap from 'gsap';

const useStyles = makeStyles((theme) => ({
  media: {
    position: 'relative',
    width:"80%",
    maxWidth:"260px",
    margin:"0 auto",
    marginBottom:'1.5rem',

  },
  card:{
    backgroundColor: 'transparent',
    padding: 8,
        [theme.breakpoints.down('md')]: {
        marginBottom:"1.5rem"
        }
  },
  background: {
    position: 'absolute',
    top: "0",
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 0,
    width: '110%',
    height:"auto",
    opacity: '0.02',
  },
  portrait: {
    width:"100%",
    height:"auto",
    position:"relative",
    zIndex:"1",
    clipPath: "ellipse(70% 50% at 50% 48%)",

  },
  content: {
    height: 'auto',
    textAlign: 'center',
    color: theme.palette.tertiary.main,
    padding:"0 !important",

    "& > span":{
      display:"block",
      marginBottom:"0.75rem",
      fontSize:"1.125rem",
      lineHeight:"1.25",
    }
  },
  title:{
    fontWeight:"bold",
    marginTop:"0.5rem",
    "&::after":{
      content:`''`,
      display:"block",
      marginTop:"0.75rem",
      position:"relative",
      left:"50%",
      marginLeft:"-24px",
      width:"48px",
      height:"4px",
      backgroundColor:theme.palette.secondary.main,
    },
  },
  slogan:{
    fontStyle: "italic",
    fontWeight: "normal"
  }
}));

function TeamMember({ name, image, title, slogan }) {
  const classes = useStyles();
  const theme = useTheme();
  const flagRef = useRef(null);
  const nameRef = useRef(null);

  const glowAnimation = (glowIntensity, glowColor) => {
    gsap.to(flagRef.current, 0.5, {
      attr: { style: `opacity: ${glowIntensity}` },
      ease: 'bounce',
    });
    gsap.to(nameRef.current, 0.5, {
      attr: { style: `color: ${glowColor}` },
      ease: 'bounce',
    });
  };

  return (
    <a
      onMouseEnter={() => glowAnimation('1', theme.palette.secondary.main)}
      onMouseLeave={() => glowAnimation('0.02', theme.palette.tertiary.main)}
    >
      <Card
      className={classes.card}
        boxShadow={0}
        elevation={0}
      >
        <CardMedia className={classes.media}>

          <img src={image} className={classes.portrait} />
          <Flag ref={flagRef} className={classes.background} />
        </CardMedia>
        <CardContent className={classes.content}>
          <Typography ref={nameRef} variant="h4">
            {name}
          </Typography>
          <span className={classes.title}>
            {title}
          </span>
          <span className={classes.slogan}>
            {slogan}
          </span>
        </CardContent>
      </Card>
    </a>
  );
}

export default TeamMember;
