import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Teaser from '../../views/HomePage/Sections/TeaserSection';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: ({animationDistance}) => ({
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: `${animationDistance}vh`,
  }),
  filter: {
    position: 'fixed',
    top: 0,
    background: 'radial-gradient(69.8% 69.8% at 49.97% 55.1%, rgba(4, 85, 111, 0) 73.44%, rgba(19, 24, 26, 0.8) 100%)',
    mixBlendMode:'multiply',
    width: '100vw',
    height: '100vh',
    zIndex: 1
  },
  teaser: ({teaserStyle}) => {


    if (teaserStyle === 'fix') {
      return ({
        position: 'absolute',
        left: 0,
        top: '50vh',
        transform: 'translate(0%, -70%)',
        zIndex: 2,
        "&>div>div":{
          maxWidth:"40rem",
        }
      })
    }
    else if (teaserStyle === 'right') {
      return ({
        position: 'absolute',
        top: 0,
        left:"auto",
        right:0,
        zIndex: 2,
        "&>div>div":{
          maxWidth:"40rem",
          alignSelf:"flex-end",
        },
      })
    }
    else if (teaserStyle === 'center') {
      return ({
        position: 'absolute',
        top: 0,
        left:"auto",
        right:"50%",
        transform:"translateX(50%)",
        zIndex: 2,
        "&>div":{
          margin:"0 0",
        },
        "&>div>div":{
        maxWidth:"48rem",
        textAlign:"left",
          "&>p":{
          maxWidth:"unset",
          },
        },
      })
    }
    else {
      return ({
        position: 'absolute',
        top: 0,
        zIndex: 2,
        "&>div>div":{
          maxWidth:"40rem",
        },
      })
    }
  },
  canvas: {
    position: 'absolute',
    left: '50vw',
    top: '50vh',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100vw',
    maxHeight: '100vh',
    zIndex: 0
  }
}));

function Flipbook({
  scenes,
  animationDistance,
  animationTime,
  animationDelay,
  onComplete,
  onEnter,
  order,
  teaser,
  teaserStyle,
  isReversible }) {
  const classes = useStyles({ animationDistance, teaserStyle } );
  const [applyFilter, setApplyFilter] = useState(false);

  // Handle window resize
  const updateWidthAndHeight = () => {
    canvas.current.width  = window.innerWidth;
    canvas.current.height = window.innerHeight;
    render();
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });


  // Handle canvas render
  const canvas = useRef();
  const teaserRef = useRef();

  function drawImageProp(canvas, img, x, y, w, h, offsetX, offsetY) {
    const context = canvas.getContext("2d");

    if (arguments.length === 2) {
        x = y = 0;
        w = canvas.width;
        h = canvas.height;
    }
    // default offset is center
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;
    // keep bounds [0.0, 1.0]
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;
    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;
    // decide which gap to fill
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;
    nw *= ar;
    nh *= ar;
    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);
    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;
    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;
    // fill image in dest. rectangle
    context.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
  }

  function render() {
    if (canvas.current){
      drawImageProp(canvas.current, scenes[hcmms.frame]);
    }
  }

  // Handle flipbook sequence
  let hcmms = {
    frame: 0
  };

  useEffect(() => {
    if (scenes.length && canvas.current){
      canvas.current.width  = window.innerWidth;
      canvas.current.height = window.innerHeight;

      if (animationTime) {
        gsap.fromTo(hcmms, {
          frame: 0
          }, {
          frame: scenes.length-1,
          duration: animationTime,
          snap: "frame",
          scrollTrigger: {
            trigger: canvas.current,
            pin: canvas.current,
            start: "top top",
            end: `+=${animationDistance/100 * canvas.current.height}`,
            toggleActions: `restart none ${isReversible ? "reverse" : "none"} none`,
            onEnter: () => { setApplyFilter(true); onEnter() },
            onEnterBack: () => { setApplyFilter(true); onEnter(); },
            onLeave: () => setApplyFilter(false),
            onLeaveBack: () => setApplyFilter(false),
          },
          onUpdate: render,
          onComplete: () => {
            onComplete();
          },
        });

      }
      else {
        gsap.fromTo(hcmms, {
          frame: 0
          },{
          frame: scenes.length-1,
          snap: "frame",
          scrollTrigger: {
            trigger: canvas.current,
            start: 'top top',
            end: `+=${animationDistance/100 * canvas.current.height}`,
            pin: canvas.current,
            scrub: 0.2,
            onEnter: () => { setApplyFilter(true); onEnter(); },
            onEnterBack: () => { setApplyFilter(true); onEnter(); },
            onLeave: () => setApplyFilter(false),
            onLeaveBack: () => setApplyFilter(false),
          },
          onUpdate: render,
          onComplete: () => {
            onComplete();
          }
        });
      }

      scenes[0].onload = render;
    }

    if (teaserRef.current && teaserStyle !== 'fix') {
      gsap.fromTo(teaserRef.current, {
        paddingTop: `${canvas.current.height}px`}, {
        paddingTop: `0px`,
        marginTop: `${-teaserRef.current.clientHeight}px`,
        scrollTrigger: {
          trigger: teaserRef.current,
          start: `top top`,
          end: `+=${animationDistance/100 * canvas.current.height}`,
          pin: teaserRef.current,
          scrub: 0.2
        },
      });
    }

    if (teaserRef.current && teaserStyle === 'fix') {
      gsap.fromTo(teaserRef.current, {
        paddingTop: `${(canvas.current.height - 2 *teaserRef.current.clientHeight) / 2}px`}, {
        paddingTop: `0px`,
        marginTop: `${-teaserRef.current.clientHeight}px`,
        scrollTrigger: {
          trigger: teaserRef.current,
          start: `top top`,
          end: `+=${animationDistance/100 * canvas.current.height}`,
          pin: teaserRef.current,
          scrub: 0.2
        },
      });
    }

  }, []);



  return (
    <div className={classes.root}>
      <canvas ref={canvas} className={classes.canvas}>
      </canvas>

      <div ref={teaserRef} className={classes.teaser}>
        {teaser && ("headline" in teaser) &&
        <Teaser teaser={{...teaser, order: order}}/>}
      </div>
      {applyFilter && <div className={classes.filter}/>}

    </div>
  );
}

export default Flipbook;
