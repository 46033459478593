import * as types from '../types';
import { gql} from 'graphql-request';

export const fetchRoutes = (language) => {
  return {
    type: types.FETCH_ROUTES,
    language: language,
    meta: {
      type: 'graphql',
      query: gql`{
        routes (locales:[${language}, de]) {
          pageType
          routeType
          label
          path
          menus {
            name
          }
        }
      }`
    }
  }
}
