import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Popover from '../../components/Popover/Popover';
import Section from '../../components/Section/Section';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyle = makeStyles((theme) => ({
  image: {
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth:"1000px"
    },
  },
  inner:{
    position:"relative"
  }
}));

function InteractiveImage({headline, subline, image, eyecatchers}) {

const classes = useStyle();
const element = useRef();
const trigger = useRef();

  React.useEffect(() => {
    gsap.fromTo(element.current, {
      y:"-10%"
      }, {
      y:"0%",
      scrollTrigger: {
        trigger: element.current,
        start: "top bottom",
        end: "top center",
        scrub: 1,
      }
    });
  });

  return (
    <Section 
      ref={trigger} 
        subline = {subline}
        headline = {headline} >
      <div ref={element} className={classes.inner}>
        <img
          src={image}
          className={classes.image}
          alt="Eyecatcher"
        />
        {eyecatchers && eyecatchers.map((eyecatcher) => (
          <Popover 
            x = {`${eyecatcher.link.x * 100}%`}
            y = {`${eyecatcher.link.y * 100}%`}
            title = {eyecatcher.headline}
            text = {eyecatcher.text}/>
        ))}
      </div>
    </Section>
  );
}

export default InteractiveImage;
