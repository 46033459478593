import React, { useContext, useEffect, useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/whiteTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Ribbon from '../../components/Ribbon/Ribbon';

const useStyles = makeStyles((theme) => ({

  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:"#fff",
    paddingBottom:"5rem",
    [theme.breakpoints.up('sm')]: {
      paddingBottom:"10rem",
    },
  },

  wrapper: {
    backgroundColor:"#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },


  formSuccess:{
    marginBottom:"auto",

    "& > div":{
      backgroundColor: "#16e098",
      padding: "2.5rem",
      color: "white",
      textAlign: "center",
      borderRadius: "4px",
    },
  },

  form: {
    maxWidth:"55rem",
  },

  requiredNotice:{
    opacity:"0.6",
    marginTop:"1.5rem",
    fontSize:"0.875rem",
    textTransform:"uppercase",
    fontWeight:"bold",

    '& b':{
      color:theme.palette.secondary.main,
      paddingRight:"0.5rem",
    },
  },

  image: {
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
}));

const pageType = "Booking";

function BookingPage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);

  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [booked, setBooked] = useState(false);

  const handleSubmit = () => {
    const baseUrl = process.env.REACT_APP_API_URL + '/api';
    const code = process.env.REACT_APP_BOOK;
    setBooked(true);

    return fetch(baseUrl + '/book' + '?code=' + code, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        name,
        firstName,
        email,
        message,
      }),
    });
  };

  useEffect(() => {
    clearAllBodyScrollLocks()
  });

  const validateInputs = () => {
    if (name.length === 0 || nameError) {
      setCanSubmit(false);
    } else if (firstName.length === 0 || firstNameError) {
      setCanSubmit(false);
    } else if (email.length === 0 || emailError) {
      setCanSubmit(false);
    } else if (message.length === 0 || messageError) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.wrapper}>
      <Paper elevation={0}>
        <Ribbon adjustOnScroll={true}/>
        {header && language in header &&
        <Header
          headline = {header[language].headline}
          subline = {header[language].subline}
          text = {header[language].text}
        />}
      </Paper>
      {!booked && (
        <Box className={classes.root}>
          <Container className={classes.form}>
            {sections && language in sections && sections[language].map((section) => {
              switch(section.type) {
                case "Contact": return (
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        error={nameError}
                        id="name"
                        label = {section.children[0].headline}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameError(e.target.value.length < 1);
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={firstNameError}
                        required
                        id="first name"
                        label = {section.children[1].headline}
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setFirstNameError(e.target.value.length < 1);
                          validateInputs();
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={emailError}
                        required
                        id="email"
                        label = {section.children[2].headline}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                          setEmailError(
                            !re.test(String(e.target.value).toLowerCase())
                          );
                          validateInputs();
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={messageError}
                        required
                        id="message"
                        label = {section.children[3].headline}
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);

                          validateInputs();
                        }}

                        variant="outlined"
                        fullWidth
                        multiline
                        rows={12}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={!canSubmit}
                        width="100%"
                        variant="contained"
                        align="center"
                        onClick={handleSubmit}
                      >
                        {section.link.label}
                      </Button>
                      <p className={classes.requiredNotice}>
                        <b>*</b>{section.text}</p>
                     </Grid>
                    </Grid>
                  )
                  default: return (<></>)
            }})}
          </Container>
        </Box>
      )}
      {booked && (
        <Container className={classes.formSuccess} fixed>
        <div>
          <Typography variant="body1">
            Herzlichen Dank für Ihr Interesse, {firstName} {name}. Wir werden in
            Kürze mit Ihnen Kontakt aufnehmen.
          </Typography>
            </div>
        </Container>

      )}
      <Footer />
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
