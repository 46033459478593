import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { fetchFlipbookScenes } from '../../redux/actions/flipbookActions';

import HomeSuspense from './HomeSuspense';
import HomePage from './HomePage';
import HomeMobile from './HomeMobile';

function Home({progress, fetchScenes}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const updateDevice = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateDevice);
    return () => window.removeEventListener("resize", updateDevice);
  });

  useEffect(() => {
    if (!isMobile && progress === 0){
      fetchScenes();
    }
  }, [isMobile]);

  return (
    <>
      {isMobile && <HomeMobile/>}
      {!isMobile && progress < 100 && <HomeSuspense/>}
      {!isMobile && progress >= 100 && <HomePage/>}
    </>
  );
}

const mapStateToProps = state => {
  return {
    progress: state.flipbook.progress,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchScenes: () => dispatch(fetchFlipbookScenes())
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
