import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as flipbookReducer, initialState as flipbookInitialState } from './reducers/flipbookReducer';
import { reducer as headerReducer, initialState as headerInitialState } from './reducers/headerReducer';
import { reducer as languageReducer, initialState as languageInitialState } from './reducers/languageReducer';
import { reducer as routeReducer, initialState as routeInitialState } from './reducers/routeReducer';
import { reducer as sectionReducer, initialState as sectionInitialState } from './reducers/sectionReducer';
import assetMiddleware from "./middleware/assetMiddleware";
import graphqlMiddleware from "./middleware/graphqlMiddleware";

export const configureStore = () => {
  const store = createStore(
    combineReducers({
      flipbook: flipbookReducer,
      header: headerReducer,
      language: languageReducer,
      routes: routeReducer,
      sections: sectionReducer,
    }),
    {
      flipbook: flipbookInitialState,
      header: headerInitialState,
      routes: routeInitialState,
      language: languageInitialState,
      sections: sectionInitialState,
    },
    applyMiddleware(
      assetMiddleware,
      graphqlMiddleware
    )
  );
  return store;
};

export default configureStore;