import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import classNames from "classnames";

import { makeStyles } from '@material-ui/core/styles';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft:"0",
    paddingRight:"0",
    margin: '0 0',
    position: 'relative',
    zIndex:"1",
    ['@media (min-width: 1024px)']: {
      paddingLeft:"8vw",
      paddingRight:"5vw",
      zIndex: 100,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft:"10vw",
      paddingRight:"7vw",
    },
  },
  inner:{
    position:"relative",
    ['@media (max-width: 1023px)']: {
    textAlign:"center",
    "& p":{
      textAlign:"left",
    }
    },

  "& a":{
    textDecoration:"none",
  }

  },
  subline: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  headline: {
    color: theme.palette.tertiary.main,
    marginBottom:"1rem",
    zIndex:"1",
    position: 'relative',

  },
  text: {
    color: theme.palette.tertiary.main,
    zIndex:"1",
    position: 'relative',
    marginBottom:"1.5rem",
    textShadow:"0 0 2px #00314A",
    maxWidth:"32rem",
      ['@media (max-width: 1023px)']: {
        maxWidth:"unset",
        textShadow:"0 0 0px #00314A",
      }
  },
  "@keyframes scroll": {
 "40%": {
   opacity: 1,
   transform: "translateY(0%)"
 },
 "100%": {
   opacity: 0.1,
   transform: "translateY(400%)"
 }
 },

   scrollIndicatorContainer:{
     color:theme.palette.secondary.main,
     fontSize:"0.875rem",
     textTransform:"uppercase",
     letterSpacing:"0.01rem",
     display:"flex",
     flexDirection:"row",
    alignItems:"center",

    ['@media (max-width: 1023px)']: {
      display:"none",
    },

     "&  span":{
       display:"block",
       fontWeight:"bold",
     },

   },

  scrollIndicator:{
    width:"1.5rem",
    borderRadius:"12px",
    height:"2.75rem",
    border:"2px solid",
    borderColor:theme.palette.secondary.main,
    display:"flex",
    justifyContent:"center",
    paddingTop:"0.25rem",
    marginRight:"0.75rem",
  },

  scrollIndicatorDot:{
    width:"0.35rem",
    margin:0,
    height:"0.35rem",
    borderRadius:"50%",
    background:theme.palette.secondary.main,
    animation:"$scroll 2s ease-in-out infinite",
  },

  btn:{
    minWidth:"13rem",
    ['@media (max-width:600px)']: {
    minWidth:"100%",
    },
  },
  number: {
    position:"absolute",
    left:"0",
    opacity:"0.4",
    height:"12rem",
    width:"auto",
    top:"0",
    transform:"translate(-40%,-25%)",
    filter:"drop-shadow( 0px 0px 12px rgba(22,224,52,0.5))",

    ['@media (max-width: 1023px)']: {
    left:"50%",
    height:"10rem",
    opacity:"0.3",
    zIndex:"0",
    transform:"translate(-50%,-20%)",
    },


  },
  link: {
    textDecoration: 'none',
  },
}));



function TeaserSection({ teaser }) {
  const classes = useStyles();

  return (
  <div className={classes.root}>
    <div className={classes.inner}>
      {(teaser.order > 0)  && <img src={require(`../../../assets/img/${teaser.order}.svg`)} className={classes.number} alt={teaser.order}/>}
      <Typography variant="h6" className={classes.subline}>
        {teaser.subline}
      </Typography>
      <Typography variant="h2" className={classNames(classes.headline,"animationTeaser")}>
        {teaser.headline && teaser.headline.split('\\n').map((headline, index) => {
          return (
            <>
              {headline}
              <br/>
            </>)}
        )}
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {teaser.text}
      </Typography>
      {(teaser.order === 0) && <div className={classes.scrollIndicatorContainer}><div className={classes.scrollIndicator}>
        <span className={classes.scrollIndicatorDot}></span></div><span>Scroll down</span>
      </div>}
      {teaser.link && 'http' in teaser.link &&
      <Link to={teaser.link.to} className={classes.link}>
        <Button
          width="100%"
          variant="contained"
          className={classes.btn}
        >
        {teaser.link.label}
        </Button>
      </Link>}
      {teaser.link && !('http' in teaser.link) &&
      <a href={teaser.link.to}>
        <Button
          width="100%"
          variant="contained"
          className={classes.btn}
        >
        {teaser.link.label}
        </Button>
      </a>}

    </div>
  </div>
  );
}

export default TeaserSection;
