import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "8rem",
    paddingBottom: "2.5rem",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",

      [theme.breakpoints.up('md')]: {
        paddingTop: "10rem",
        paddingBottom: "10rem",
      }


  },
  subline: {
    textAlign: 'center',
  },
  headline: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    maxWidth:"65rem",
  },
  text:{
    marginTop:"0.5rem",
    textAlign: 'center',
  }
}));

function Header({ headline, subline,text, ...props }) {
  const classes = useStyles();
  return (
    <Container fixed className={classes.root}>
      <Typography variant="h6" className={classes.subline}>
        {subline}
      </Typography>
      <Typography variant="h1" className={classes.headline}>
        {headline}
      </Typography>
      <Typography variant="subtitle1" className={classes.text}>
        {text}
      </Typography>
    </Container>
  );
}

export default Header;
