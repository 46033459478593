import React, { useEffect, useRef } from 'react';

import { Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


const useStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '2.5rem',
    marginBottom: '5rem',
    maxWidth:"60rem",
    margin:"0 auto",

    [theme.breakpoints.up('md')]: {
    marginBottom: '8rem',
    marginTop: '6rem'
    }
  },
  firstLetterParagraph: {
    position: 'absolute',
    fontWeight: 'bold',
    transform: 'translate(-30%, -20%)',
    left:"0",
    top:"0",
    zIndex:"0",
    color: theme.palette.secondary.main,
    zIndex: 0,
    opacity:"0.8",

    [theme.breakpoints.down('sm')]: {
    transform: 'translate(5%, -20%) !important',
  },

  },
  text: {
    zIndex: 1,
    position:"relative",
    zIndex:"1",
    hyphens:"auto",
  },
}));

function Paragraph(props) {

const classes = useStyle();
const firstLetter = useRef();
const trigger = useRef();

  React.useEffect(() => {
    gsap.fromTo(firstLetter.current, {
      scale:"1",
      x:"-30%",
      y:"-20%"
      }, {
      scale:"1.2",
      x:"-30%",
      y:"-20%",
      scrollTrigger: {
        trigger: trigger.current,
        start: "top center",
        end: "150",
        scrub: 0.5,
      }
    });
  });


  return (
    <Container  className={classes.root}>
      <Typography ref={trigger}  variant="body1" className={classes.text}>
        {props.children}
      </Typography>
      <div ref={firstLetter} className={classNames(classes.firstLetterParagraph,"firstLetter")}>
        {props.children[0]}
      </div>
    </Container>
  );
}

export default Paragraph;
