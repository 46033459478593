import React from 'react';
import { connect } from "react-redux";

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#00314A',
    position: 'relative',
    overflowX: false,
    overflowY: false
  },
  logo: {
    objectFit: 'contain',
    width: '30vw',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  progressBar: (progress) => ({
    backgroundColor: '#16e098',
    height: `${progress}vh`,
    width: '100vw',
    position: 'absolute',
    transition:"all 0.2s",
    bottom: 0,
  }),
  progressCounter: {
    textAlign: 'right',
    color: '#FFFFFF',
    margin: '30px',
  },
}));

const logo = require('../../assets/img/logo_dark.png');

function HomeSuspense({progress}) {
  const classes = useStyles(progress);

  return (
    <Box className={classes.root}>
      <img src={logo} className={classes.logo} />
      <Box className={classes.progressBar}>
        <Typography variant="h1" className={classes.progressCounter}>
          {Math.ceil(progress)} %
        </Typography>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    progress: state.flipbook.progress
  };
};

export default connect(mapStateToProps)(HomeSuspense);
