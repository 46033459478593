import { createMuiTheme } from '@material-ui/core/styles';
import commonThemeSettings from './commonThemeSettings';


const theme = createMuiTheme({

  ...commonThemeSettings,

  palette: {
    primary: {
      light: '#b3bfc3',
      main: '#012b38',
      dark: '#ffffff',
    },
    secondary: {
      light: '#b9f6e0',
      main: '#16e098',
      dark: '#0cd37b',
    },
    tertiary: {
      main: '#ffffff',
    },
    background: {
      paper: '#00314A',
      default: '#00314A',
    },

    type: 'dark',
  },

  name: 'blue',
});

export default theme;
