import React, { useContext, useEffect } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/whiteTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Container, Paper } from '@material-ui/core';

import Header from '../../components/Header/Header';
import Ribbon from '../../components/Ribbon/Ribbon';
import Footer from '../../components/Footer/Footer';
import Ad from '../../components/Ad/Ad';
import Paragraph from '../../components/Paragraph/Paragraph';
import EinsatzPageTile from './EinsatzPage-Tile';


const useStyles = makeStyles((theme) => ({
tileContainer:{
  position:"relative",

  "& > div":{
    "& > div":{
      marginRight:"2rem",
      [theme.breakpoints.down('sm')]: {
        marginRight:"0",
      }
    },
  },

  // left:"10vw",
  "& > div:nth-child(2n+1)":{
    flexDirection:"row-reverse",
    "& > div":{
      marginLeft:"2rem",
      [theme.breakpoints.down('sm')]: {
        marginLeft:"0",
      }
    },

    [theme.breakpoints.down('sm')]: {
    flexDirection:"column",
    }
  },
  [theme.breakpoints.down('sm')]: {
  left:"0%",
  }
}

}));

const pageType = "Activities";

function EinsatzPage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);
  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    clearAllBodyScrollLocks()
  });

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0}>
        <Ribbon adjustOnScroll={true}/>
        {header && language in header &&
        <Header
          headline = {header[language].headline}
          subline = {header[language].subline}
        />}
      </Paper>

      <Box>
        {header && language in header &&
        <Paragraph>
          {header[language].text}
        </Paragraph>}

        <Container  className={classes.tileContainer} >
          {sections && language in sections && sections[language].map((section) => {
            switch(section.type){
              case "Activity": return (
                <EinsatzPageTile
                  name = {section.headline}
                  description = {section.text}
                  image = {section.image}
                />)
              default: return (<></>)            
            }
          })}      
        </Container>
      </Box>

      {sections && language in sections && sections[language].map((section) => {
        switch(section.type){
          case "Ad": return (
            <Ad 
              headline = {section.headline}
              subline = {section.subline}
              text = {section.text}
              link = {section.link}
              image = {section.image} />
          )
          default: return (<></>)            
        }
      })}      

      <Footer />
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
});

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EinsatzPage);
