import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { connect } from "react-redux";
import { fetchRoutes } from '../redux/actions/routeActions';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from "@material-ui/core";
import Theme from '../themes/Theme';
import entryTheme from '../themes/blueTheme';

import BookingPage from '../views/BookingPage/BookingPage';
import Home from '../views/HomePage/Home';
import InteriorPage from '../views/InteriorPage/InteriorPage';
import SponsoringPage from '../views/SponsoringPage/SponsoringPage';
import ImpressumPage from '../views/ImpressumPage/ImpressumPage';
import EinsatzPage from '../views/EinsatzPage/EinsatzPage';


var hist = createBrowserHistory();

function App(props) {
  const { language, routes, loadRoutes } = props;
  const [theme, setTheme] = useState(entryTheme);

  useEffect(() => {
    if (!(routes && language in routes)) {
      loadRoutes(language);
    }
  }, [language]);

  const pages = {
    'Landing': <Home/>,
    'Interior': <InteriorPage/>,
    'Sponsoring': <SponsoringPage/>,
    'Activities': <EinsatzPage/>,
    'Booking': <BookingPage/>,
    'Impressum': <ImpressumPage/>
  }

  return (
    <ThemeProvider theme={theme}>
      <Theme.Provider value={[theme, setTheme]}>
      <CssBaseline />
        <Router history={hist}>
          {routes && language in routes &&
          <Switch>
            {routes[language].map(route => 
              <Route path={route.path} exact render={(props) => pages[route.pageType]} />
            )}
          </Switch>
          }
        </Router>

      </Theme.Provider>
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadRoutes: (language) => dispatch(fetchRoutes(language)),
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    routes: state.routes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
