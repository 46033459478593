import { request } from 'graphql-request';

const graphqlMiddleware = store => next => action => {
  if (!action.meta || action.meta.type !== 'graphql') {
    return next(action);
  }

  const fetchData = async () => {
    const data = await request(
      'https://api-eu-central-1.graphcms.com/v2/ckdznurhe6vto01xu877hf5xo/master',
      action.meta.query
    );

    let newAction = Object.assign({}, action, {
      payload: data
    });

    delete newAction.meta;
    store.dispatch(newAction);
  }

  fetchData();  
}

export default graphqlMiddleware;