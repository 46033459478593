import * as types from '../types';
import { gql} from 'graphql-request';


export const fetchHeader = (pageType, language) => {
  return {
    type: types.FETCH_HEADER,
    language: language,
    meta: {
      type: 'graphql',
      query: gql`{
        pages (where: {type: ${pageType}}, locales:[${language}, de]) {
          type
          header {
            headline
            subline
            text
          }
        }
      }`
    }
  }
}
