import * as types from '../types'

export const initialState = {
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SECTIONS: {
      const { sections, type } = action.payload.pages[0];
      const locale = action.language;
      for (let section of sections) {
        if (section.image){
          const w = section.image.width;
          const h = section.image.height;
          if (w>h) {
            section.image.format = "wide";
          } else if (w<h) {
            section.image.format = "portrait";
          } else { 
            section.image.format = "circle"
          }
        }
      }
      return {
        ...state,
        [type]: {
          ...state[type],
          [locale]: sections
        }
      };
    }
    default:
      return state;
  }
}
