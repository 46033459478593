import React from 'react';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '160px',
    color: theme.palette.tertiary.main,
    backgroundColor: theme.palette.background.banner,
    borderRadius:"8px",
  },
}));

function Banner(props) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      {React.Children.toArray(props.children).map((child) => child)}
    </Paper>
  );
}

export default Banner;
