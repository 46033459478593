import * as types from '../types'

export const initialState = {
  locale: "de"
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANGUAGE: {
      return {
        locale: action.payload
      };
    }
    default:
      return state;
  }
}
