import React from 'react'
import { connect } from "react-redux";
import { setLanguage } from '../../redux/actions/languageActions';


import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

import { ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  toggle:{
    border:"2px solid",
    borderColor:theme.palette.secondary.main,
    borderRadius:"4px",
    "& li":{
      margin:"0",
      padding:"0",
    },
    "& button:first-of-type::before":{
      content:`''`,
      position:'absolute',
      width:"100%",
      height:"100%",
      left:"0",
      right:"0",
      zIndex:"0",
      backgroundColor:theme.palette.secondary.main,
      transform:"translateX(100%)",
      transition:"all 0.2s ease-in-out",
    },
    "& button:first-of-type.Mui-selected::before":{
      transform:"translateX(0%)",
    },
    [theme.breakpoints.down('sm')]: {
      display:"none"
    }
  },

  smallToggle:{
    display:"none",
    [theme.breakpoints.down('sm')]: {
      display:"block",
      color:"red"
    }
  },

  toggleBtn:{
    borderRadius:"0",
    padding: "0.75rem 1rem",
    border:"0",
    fontWeight:"bold",
    color:theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      color:"white"
    },
    "&:hover":{
      backgroundColor:"transparent",
      color:theme.palette.secondary.main,
    },
    "& span":{
      position:"relative",
      zIndex:"1",
    },
    "&.Mui-selected":{
      backgroundColor:"transparent",
      color:"white",
      "&:hover":{
        backgroundColor:"transparent",
        color:"white",
      },
    }
  }
}));

function LanguageToggle({isSmall, ...props}){
  const { language, setLanguage } = props;
  const classes = useStyles();
  const languages = ['de', 'en'];

  const handleLanguage = (_event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      className={ isSmall ? 
        classNames(classes.toggle, classes.smallToggle) : 
        classes.toggle}
      key="languages"
      value={language}
      onChange={handleLanguage}>
      {languages.map((language) => (
        <ToggleButton
          className={classes.toggleBtn}
          key={language}
          value={language}>
            {language}
        </ToggleButton>
    ))}
    </ToggleButtonGroup>
  );
}

const mapDispatchToProps = dispatch => ({
  setLanguage: (language) => dispatch(setLanguage(language))  
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggle);