import React, { useContext, useEffect, useRef, useState} from 'react';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/blueTheme';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../../components/Footer/Footer';
import Ribbon from '../../components/Ribbon/Ribbon';
import Flipbook from '../../components/Flipbook/Flipbook';
import PageIndicator from '../../components/PageIndicator/PageIndicator';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  flipbookSection: {
    position: 'relative',
    overflowX: 'hidden'
  },
  returnBtn:{
    fontSize:"1.5rem",

    "& span":{
      zIndex:"1"
    },
  }
}));

const pageType = "Landing";

function HomePage(props) {
  const { language, header, loadHeader, sections, loadSections, scenesWithVan, scenesWithoutVan } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);
  const [reverse, setReverse] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);

  const flipbookSections = [
    {
      teaserId: 0,
      teaserStyle: 'fix',
      startIndex: 0,
      endIndex: 44,
      animationTime: 2,
      animationDistance: 100
    },
    {
      teaserId: 1,
      teaserStyle: 'left',
      startIndex: 44,
      endIndex: 140,
      animationDistance: 100
    },
    {
      teaserId: 2,
      teaserStyle: 'left',
      startIndex: 140,
      endIndex: 250,
      animationDistance: 100
    },
    {
      teaserId: 3,
      teaserStyle: 'left',
      startIndex: 250,
      endIndex: 435,
      animationDistance: 100,
    },
    {
      teaserId: 4,
      teaserStyle: 'left',
      startIndex: 435,
      endIndex: 544,
      animationDistance: 100
    },
    {
      teaserId: 5,
      teaserStyle: 'right',
      startIndex: 544,
      endIndex: 634,
      animationDistance: 100
    },
    {
      startIndex: 634,
      endIndex: 889,
      animationDistance: 100,
      animationTime: 2,
    },
    {
      teaserId: 6,
      teaserStyle: 'left',
      startIndex: 889,
      endIndex: 890,
      animationDistance: 100
    },
    {
      startIndex: 890,
      endIndex: 940,
      animationDistance: 100
    },
    {
      teaserId: 7,
      teaserStyle: 'center',
      startIndex: 940,
      endIndex: 975,
      animationDistance: 100
    },
    {
      startIndex: 975,
      endIndex: 988,
      animationTime: 1,
      animationDistance: 200,
    }
  ];

  const handleForward = () => {
    setReverse(false);
  }

  const handleBackward = () => {
    setReverse(true);
  }

  const handleActiveSection = (section) => {
    setActiveSection(section);
    setShowFooter(section === flipbookSections.filter(section => 'teaserId' in section).length - 1);
  }

  return (
    <ThemeProvider theme={theme}>
      <Ribbon transparent adjustOnScroll={false}/>

      <PageIndicator
        nDots = {flipbookSections.filter(section => 'teaserId' in section).length }
        activeSection = {activeSection} />

      {showFooter &&
      <div style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        zIndex: 1000 }}>
          <Footer transparent={true}/>
      </div>}

      {!reverse && sections && language in sections &&
      <div className={classes.root}>
        {flipbookSections.map((section, index) => (
          <div
            className={classes.flipbookSection}
            style={{zIndex: flipbookSections.length - index}}>
            <Flipbook
              scenes={scenesWithVan.slice(section.startIndex, section.endIndex)}
              animationDistance={section.animationDistance}
              animationTime={section.animationTime}
              teaser = {"teaserId" in section ? sections[language][section.teaserId] : null}
              order = {section.teaserId}
              teaserStyle = {section.teaserStyle}
              isReversible = {index > 0}
              onComplete = {index === flipbookSections.length - 1 ?
                () => { handleBackward() } :
                () => {}}
              onEnter = {'teaserId' in section ?
                () => handleActiveSection(flipbookSections.slice(0,index).filter(section => 'teaserId' in section).length) :
                () => {}
              }
              />
          </div>
        ))}
      </div>
      }

      {reverse && sections && language in sections &&
      <div className={classes.root}>
        {flipbookSections.map((section, index) => (
          <>
           <div
              className={classes.flipbookSection}
              style={{zIndex: flipbookSections.length - index}}>
              <Flipbook
                scenes={scenesWithoutVan.slice(section.startIndex, section.endIndex)}
                animationDistance={section.animationDistance}
                animationTime={section.animationTime}
                teaser =  {index !==0 && "teaserId" in section ? sections[language][section.teaserId] : null}
                order = {section.teaserId}
                teaserStyle = {section.teaserStyle}
                onComplete = {() => {}}
                onEnter = {() => {}}
              />
            </div>
            {index===0 && <Button
              className={`${classes.returnBtn} returnBtn`}
              variant="contained"
              style={{
                left:'50vw',
                top: '50vh',
                transform:"translate(-50%,-50%)",
                position: 'absolute',
                zIndex: 100
              }}
              onClick={handleForward}>
              Back to the future
            </Button>}
          </>
            ))}
      </div>
      }
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType],
    scenesWithVan: state.flipbook.scenesWithVan.array,
    scenesWithoutVan: state.flipbook.scenesWithoutVan.array,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
