import { createMuiTheme } from '@material-ui/core/styles';
import commonThemeSettings from './commonThemeSettings';

const green = "#16e098";

const theme = createMuiTheme({

  ...commonThemeSettings,

  palette: {
    text:{
      primary:"#011A38",
    },
    primary: {
      light: '#b3bfc3',
      main: '#012b38',
      dark: '#011A38',
    },
    secondary: {
      light: '#b9f6e0',
      main: '#16e098',
      dark: '#0cd37b',
    },
    tertiary: {
      main: '#ffffff',
    },
    action: {
      disabledBackground:"rgba(22, 224, 152, 0.6)",
      disabled:"rgba(255,255,255,0.8)",
      focused:green,
    },
    background: {
      banner: '#00314A',
      paper: '#ffffff',
      default: '#F7F7F7',
    },
    type: 'light',
  },

  name: 'white',
});

export default theme;
