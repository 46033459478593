import React, { useContext, useEffect } from 'react';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';

import Theme from '../../themes/Theme';
import localTheme from '../../themes/whiteTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Paper, Typography } from '@material-ui/core';

import Ad from '../../components/Ad/Ad';
import Header from '../../components/Header/Header';
import Paragraph from '../../components/Paragraph/Paragraph';
import Ribbon from '../../components/Ribbon/Ribbon';
import Section from '../../components/Section/Section';
import Footer from '../../components/Footer/Footer';
import FeatureTable from './Sections/FeatureTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "theme.palette.background.default,"
  },
  image: {
    display: 'block',
    borderRadius:"50%",
    margin: 'auto',
    width: '80%',
    backgroundColor:"white",
    backgroundSize:"200%",
    backgroundPosition:"-15% center",
    backgroundRepeat:"no-repeat",
    "&::before":{
      content:`''`,
      paddingTop:"100%",
      display:"block",
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const pageType = "Sponsoring";

function SponsoringPage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  
  const [theme, setTheme] = useContext(Theme);
  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0}>
        <Ribbon adjustOnScroll={true}/>
        {header && language in header &&
        <Header
          headline = {header[language].headline}
          subline = {header[language].subline}
        />}
      </Paper>

      <Box className={classes.root}>
        {header && language in header &&
        <Paragraph>
          {header[language].text}
        </Paragraph>}
        
        {sections && language in sections && sections[language].map((section) => {
          switch(section.type){
            case "Ad": return (
              <Ad 
                headline = {section.headline}
                subline = {section.subline}
                text = {section.text}
                link = {section.link}
                image = {section.image} />
            )
            case "Packages": return (
              <Section
                headline = {section.headline}
                subline = {section.subline}
                columns={2}>
                <Box>
                  <Typography variant="body2">
                    {section.text}
                  </Typography>
                </Box>
                <div 
                  className={classes.image} 
                  style = {{backgroundImage: `url(${section.image.url})`}}></div>
              </Section>
            )
            case "Features": return (
              <Section
                headline = {section.headline}
                subline = {section.subline}
                text = {section.text}>
                <FeatureTable
                  features = {section.children.filter((child) => child.type === "Feature")}
                  pkgs = {section.children.filter((child) => child.type === "Package")}
                />
              </Section>
            )
            default: return (<></>)            
          }
        })} 

      </Box>
      <Footer />
    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsoringPage);
