import * as types from '../types'

export const initialState = {
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HEADER: {
      const { header, type } = action.payload.pages[0];
      const locale = action.language;
      return {
        ...state,
        [type]: {
          ...state[type],
          [locale]: header
        }
      };
    }
    default:
      return state;
  }
}
