import React, { useEffect, useRef } from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pattern from '../../assets/img/pattern.svg'; // Import using relative path

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: '15rem',
      [theme.breakpoints.down('md')]: {
      marginBottom: '5rem',
      }
  },
  headline: {
    marginBottom:'1.5rem',
  },
  content:{
  [theme.breakpoints.down('md')]: {
    flexDirection:"column-reverse",
  }
  },
  image:{
    '& figure':{
      width:"100%",
      maxWidth:"800px",
      position:"relative",
      margin:"0",

      '&::before':{
        content:`''`,
        position:'absolute',
        width:"100%",
        height:"100%",
        left:"0",
        right:"0",
        zIndex:"0",
        transform:"translate(2.5rem,2.5rem)",
        backgroundImage:`url(${Pattern})`,
        backgroundRepeat: "repeat round",

        [theme.breakpoints.down('md')]: {
          transform:"translate(0rem,1.5rem)",
        },

      },

      '& img':{
      width:"100%",
      height:"auto",
      position:"relative",
      zIndex:"1"
    },
      [theme.breakpoints.down('md')]: {
        marginBottom:"3.5rem",
      },


    },
  }
}));

function SectionSplit(props) {
  const classes = useStyles();

  const element = useRef();

    React.useEffect(() => {
      gsap.fromTo(element.current, {
        skewY:-3,
        }, {
        skewY:0,
        scrollTrigger: {
          trigger: element.current,
          start: "top bottom",
          end: "top center",
          scrub: 0.5,
        }
      });
    });



  return (
    <Container ref={element} className={classes.root}>

      <Grid container spacing={4} className={classes.content}>
        <Grid item sm={12} md={5} >
                <Typography variant="h6" className={classes.subline}>
                  {props.subline}
                </Typography>
                <Typography variant="h2" className={classes.headline}>
                  {props.headline}
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  {props.text}
                </Typography>
                </Grid>

                <Grid item sm={12} md={7} className={classes.image} >
                  {props.children}
                </Grid>

      </Grid>
    </Container>
  );
}

export default SectionSplit;
