import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: '15rem',
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    backgroundColor: theme.palette.background.banner,
    color:"white",
    padding:"8rem 0",
    [theme.breakpoints.down('md')]: {
    marginBottom: '5rem',
    padding:"2rem 0",
  },
  },

  subline: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom:"2rem"
  },
  text: {
    marginTop:"1rem",
    fontStyle:"italic",
    fontSize:"1.25rem",
    maxWidth:"55rem",
    textAlign:"center",
    opacity:"0.8",
    lineHeight:"1.3"
  }
}));

function SectionFull(props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.subline}>
        {props.subline}
      </Typography>
      <Typography variant="h2" className={classes.headline}>
        {props.headline}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {props.text}
      </Typography>
      {props.children}
    </Box>
  );
}

export default SectionFull;
