import * as types from '../types'

export const initialState = {
  scenesWithVan: {
    byId: {},
    array: []
  },
  scenesWithoutVan: {
    byId: {},
    array: []
  },
  progress: 0,
}

const nScenes = 989;

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FLIPBOOK_SCENES_WITH_VAN: {
      let newState = {
        ...state,
        scenesWithVan: {
          ...state.scenesWithVan,
          byId: {...state.scenesWithVan.byId, ...action.payload},
        },
        progress: 100 * (Object.keys(state.scenesWithVan.byId).length + 1) / nScenes
      };
      if (newState.progress === 100){
        newState.scenesWithVan.array = Object.values(state.scenesWithVan.byId)
      }
      return newState;
    }
    case types.FETCH_FLIPBOOK_SCENES_WITHOUT_VAN: {
      let newState = {
        ...state,
        scenesWithoutVan: {
          ...state.scenesWithoutVan,
          byId: {...state.scenesWithoutVan.byId, ...action.payload},
        }
      };
      if (Object.values(newState.scenesWithoutVan.byId).length === nScenes){
        newState.scenesWithoutVan.array = Object.values(state.scenesWithoutVan.byId)
      }
      return newState;
    }
    default:
      return state;
  }
}

