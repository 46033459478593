const green = "#16e098";

export default {
  props:
  {
    MuiButtonBase: {
      disableFocusRipple: true,
      disableRipple: true,
    },

    MuiTextField: {
      variant: 'outlined',
    },

    MuiTypography: {
      variantMapping: {
        subtitle1: 'p',
      },
    },
  },

  overrides:
  {
    MuiAccordionSummary:{
      content:{
          margin:"1.5rem 0",
          transition:"color 0.3s",
          "&$expanded": {
              color:green,
          },
      }
    },

    MuiCssBaseline: {
      '@global': {
        body: {
          fontSize:"inherit",
        },
        html:{
          lineHeight:"1.5",
          fontSize:"16px"
        }
      },
    },

    MuiOutlinedInput: {
      root: {
      borderRadius:"8px",
      "& $notchedOutline": {
          borderColor: "rgba(1, 26, 56, 0.5)"
        },
        "&:hover $notchedOutline": {
          borderColor: "rgba(1, 26, 56, 0.8)"
        },
        "&$focused $notchedOutline": {
          borderColor: green
        }
      },
      input:{
        fontSize:"1.125rem",
      },
    },

    MuiFormLabel: {
      root: {
        "&$focused": {
          color: green,
        }
      },
    },

    MuiInputLabel: {
      root:{
        fontSize:"1.125rem",
      }
    },

    MuiButton: {
      contained:{
        backgroundColor:green,
        boxShadow:"none",
        color:"white",
        transition:"box-shadow 0.3s",
        '&::before':{
          content:`''`,
          position:'absolute',
          width:"100%",
          height:"100%",
          left:"0",
          right:"0",
          zIndex:"1",
          border: "2px solid",
          borderColor:green,
          transition:"all 0.3s",
          borderRadius:"inherit",
          opacity:"0.3"
        },
        '&:hover':{
          boxShadow: "0px 0px 10px #16E098",
          backgroundColor:green,
          '&::before':{
            width:"calc(100% + 16px)",
            height:"calc(100% + 16px)",
            transform:"translate(-8px,0)",
          },
        },
      },

      outlined:{
        color:green,
        border: "2px solid",
        borderColor:green,
        padding:"0.75rem 1rem",
        transition:"all 0.3s",
        textDecoration:"none",

        '&:hover':{
          backgroundColor:green,
          color:"white",

        },

      },

      root:{
        padding:"1rem 1.5rem",
        borderRadius:"4px",
        fontSize:"0.875rem",
        letterSpacing:"0.02rem",
        textTransform:"uppercase",
        fontWeight:"bold",
        color:"white",
        position:"relative",
      },
    },

    disabledButton:{
      backgroundColor:green,
      opacity:"0.5",
      color:"white",
    },

    MuiContainer:{
    }
  },

  typography:
  {
    fontFamily: 'Friends, Arial',
    h1:{
      fontWeight:"bold",
    },
    h6:{
      fontWeight:"bold",
    },
    h2:{
      fontWeight:"bold",
    },
    h3:{
      fontWeight:"bold",
    },
    h4:{
      fontWeight:"bold",
    },
    subtitle1:{
      fontStyle:"italic",
    }
  },

  shape: {
    borderRadius: '0',
  }
}
