import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import RibbonLinks from './RibbonLinks';

const logo = require('../../assets/img/logo-mkm.svg');

const useStyles = makeStyles((theme) => ({
  menu: {
    color: theme.palette.secondary.main,
  },
  paper:{
    backgroundColor:theme.palette.background.banner,
    width:"100%",
    color:"#fff",
    padding:"1.5rem",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    justifyContent:"center",

    "& img":{
      minWidth:"120px",
      width:"20vw",
      height:"auto",
      flexGrow:"0",
    }
  },
  mobileMenuHeader:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between",
    alignItems:"flex-start"
  }
}));

export default function RibbonMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MenuIcon style={{ fontSize: 32 }} className={classes.menu} />
      </IconButton>
      <Drawer
        classes={{ paper: classes.paper }}
        anchorEl={anchorEl}
        anchor={"right"}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      <div className={classes.mobileMenuHeader}>
      <img src={logo} />
      <IconButton aria-haspopup="true" onClick={handleClose}>
        <CloseIcon style={{ fontSize: 32 }} className={classes.menu} />
      </IconButton>

      </div>
        <RibbonLinks />
      </Drawer>
    </>
  );
}
