import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Banner from '../Banner/Banner';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root:{
      alignItems: 'center',
      padding:"0 15vw",
      paddingTop:"5rem",
      paddingBottom:"2rem",
      position:"relative",
      alignItems:"center",
      display:"flex",

      backgroundColor:theme.palette.background.banner,
      [theme.breakpoints.down('sm')]: {
      flexDirection:"column-reverse",
      padding: '1.5rem 1rem 1.5rem 1rem',
      textAlign:"center",
      alignItems:"center",
    },
  },
  container: {
    color:"#fff",
    maxWidth:"none",
    padding:"0",

    "& img":{
      maxWidth:"700px",
      width:"100%",
      height:"auto",


      [theme.breakpoints.down('sm')]: {
        position:"relative",
        width:"70%",
      },
    },


  },
  subline: {
    textTransform: 'uppercase',
    textAlign: 'flex-start',
    color: theme.palette.secondary.main,
  },
  headline: {
    textAlign: 'flex-start',
    fontWeight: 'bold',
    marginBottom:"8px",
    marginTop:"8px",
    whiteSpace: "pre-line",
  },
  link: {
    textDecoration: 'none',
    marginTop:"1.5rem",
    display:"block",
  },
  text:{
    maxWidth:"40rem"
  }
}));

function Ad({headline, subline, text, link, image}) {
  const classes = useStyles();

  const element = useRef();
  const trigger = useRef();

  useEffect(() => {
    gsap.fromTo(element.current,{
      x:"30%",
      opacity:"0.3",
      }, {
      x:"0%",
      opacity:"1",
      scrollTrigger: {
        trigger: trigger.current,
        start:"10% bottom",
        end:"bottom bottom",
        scrub:"0.5",
      },
    });
  });

  return (
    <Container ref={trigger} className={classes.container}>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className={classes.subline}>
            {subline}
          </Typography>
          <Typography variant="h2" className={classes.headline}>
            {headline}
          </Typography>
          <Typography className={classes.text} variant="body2">
            {text}
          </Typography>
          <Link to={link.to} className={classes.link}>
            <Button
              width="100%"
              variant="contained"
              align="center"
            >
              {link.label}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={image.url}
            ref={element}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Ad;
