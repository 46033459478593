import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { fetchRoutes } from '../../redux/actions/routeActions';

import { Link, NavLink } from 'react-router-dom';
import classNames from "classnames";

import { makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItem } from '@material-ui/core';

import LanguageToggle from './LanguageToggle';

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow:"1",
    display: 'flex',
    flexDirection:"column",
    justifyContent:"flexStart",
    paddingTop:"10vh",

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: 0,
      justifyContent:"center",
    },
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    textTransform:"uppercase",
    fontWeight:"bold",
    letterSpacing:"0.02rem",
    color:"#fff",
    transition:"color 0.3s",
    fontSize:"1.5rem",

      [theme.breakpoints.up('md')]: {
      fontSize:"1rem",
      color:theme.palette.primary.dark,
      },

    "&:hover":{
      color:theme.palette.secondary.main,
    },
    "&.selected":{
        color:theme.palette.secondary.main,
    },


  },
  listElement:{
    whiteSpace:"nowrap",
    padding:"0",
    marginBottom:"1.5rem",

    [theme.breakpoints.up('md')]: {
    padding:"0 0.75rem",
    marginBottom:"0",
  },

  },
  
  buttonNav:{
  padding: "0.75rem 1rem",
  border:"2px solid",
  borderColor:theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize:"1.25rem",
  },
  },
  
  mobileFooterMenu:{
    display:"block",
    paddingTop:"1.5rem",
    paddingBottom:"0",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    width:"100%",
    borderTop:"1px solid rgba(255,255,255,0.3)",
    [theme.breakpoints.up('md')]: {
      display:"none"
    },
    "& li":{

      width:"auto",
      marginRight:"1.5rem",
      marginBottom:"0",
      "& a":{
        fontSize:"0.875rem",
      }
    },
  }

}));

function RibbonLinks(props) {
  const { language, routes, loadRoutes } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!(routes && language in routes)) {
      loadRoutes(language);
    }
  }, [language]);

  return (
    <>
      <List className={classes.list}>
        {routes && language in routes && routes[language]
          .filter((route) => 
            route.menus.filter((menu) =>
              menu.name === 'Ribbon')
            .length)
          .map((route) =>  {
            switch (route.routeType){
              case 'Link':
                return (
                  <ListItem className={classes.listElement} key={route.pageType}>
                    <NavLink activeClassName="selected" className={classes.link} to={route.path}>
                      {route.label}
                    </NavLink>
                  </ListItem>
                );
              case 'Button':
                return (
                  <ListItem className={classes.listElement} key={route.pageType}>
                    <Link className={classes.link} to={route.path}>
                      <Button
                        width="100%"
                        variant="contained"
                        className={classes.buttonNav}
                      >
                        {route.label}
                      </Button>
                    </Link>
                  </ListItem>
                );
              default: return null
            }
          })
        }
        <ListItem className={classes.listElement} key="languageToggle">
          <LanguageToggle isSmall={false}/>
        </ListItem>
      </List>

      <List className={classes.mobileFooterMenu}>
        <ListItem className={classes.listElement}>
          <LanguageToggle isSmall={true}/>
        </ListItem>
        {routes && language in routes && routes[language]
          .filter((route) => 
            route.menus.filter((menu) =>
              menu.name === 'Ribbon')
            .length)
          .map((route) => {
            switch (route.routeType){
              case 'Small':
                return(
                  <ListItem className={classes.listElement} key={route.pageType}>
                    <Link className={classNames(classes.link, classes.small)} to={route.path}>
                      {route.label}
                    </Link>
                  </ListItem>
                );
              default: return null;
            }
          })
        }
      </List>
  </>);
}

const mapDispatchToProps = dispatch => ({
  loadRoutes: (language) => dispatch(fetchRoutes(language)),
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    routes: state.routes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RibbonLinks);
