import React from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Container';
import { Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckIcon from '../../../assets/img/check.svg'; // Import using relative path

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth:200,
    backgroundColor:"white",
    borderRadius:"12px",
    boxShadow:"0px 0px 12px rgba(1, 43, 56, 0.1)",
  },
  tableContainer:{
    overflowX:"visible",

    '& td':{
      width:"23.33%",
      minWidth:"200px",
      '& p':{
      fontSize:"1.125rem",
      [theme.breakpoints.down('sm')]: {
      fontSize:"1rem",
    },
      },
    },
    '& tbody th':{
      fontWeight:"bold",
      lineHeight:"1.3",
      fontSize:"1.125rem",
      width:"30%",
      [theme.breakpoints.down('sm')]: {
      fontSize:"1rem",

    },
    },
    [theme.breakpoints.down('sm')]: {
    width:"calc( 100% + 1rem)",
    overflowX:"scroll",
  },
  },
  tableHead:{

    "& th":{
        top:"88px",
      padding:"3rem 0",
      backgroundColor:"#fff",
        [theme.breakpoints.down('sm')]: {
          padding:"2rem 0"
        }
    }
  },
  link:{
    textDecoration:"none",
    marginTop:"1rem",
    display:"block",
  },
  small:{
    fontSize:"1rem",
    opacity:"0.8",
    display:"block",
    marginTop:"-0.5rem"
  }

}));

function intersect(feature, pkg, index) {
  if (feature.children.map((child) => child.headline).includes(pkg.headline)) {
    return feature.link ?  
      <Typography variant='body1'>{feature.link.markers[index]}</Typography> :
      <img src={CheckIcon} alt="check"/>
  }
  else {
    return ''
  }
}

function FeatureTable({ features, pkgs }) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <TableContainer className={classes.tableContainer} component={Box}>
      <Table stickyHeader={matches} className={classes.table} aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell></TableCell>
            {pkgs && pkgs.map((pkg) => (
              <TableCell align="center">
                <Typography variant="h3">
                  {pkg.headline}
                </Typography>
                <Typography className={classes.small} variant="small">
                  {pkg.subline}
                </Typography>
                {pkg.link &&
                <Link 
                  to={pkg.link.to} 
                  className={classes.link}>
                  <Button
                    width="100%"
                    variant="outlined"
                    align="center">
                    {pkg.link.label}
                  </Button>
                </Link>}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody >
          {features && features.map((feature) => (
            <TableRow key={feature.headline}>
              <TableCell component="th" scope="row">
                {feature.headline}
              </TableCell>
              {pkgs && pkgs.map((p, index) => (
                <TableCell align="center">{intersect(feature, p, index)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FeatureTable;
