import * as types from '../types'

export const initialState = {
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROUTES: {
      const { routes } = action.payload;
      const locale = action.language;
      return {
        ...state,
        [locale]: routes
      };
    }
    default:
      return state;
  }
}
