import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { fetchRoutes } from '../../redux/actions/routeActions';

import { Link } from 'react-router-dom';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const logo = require('../../assets/img/logo-hcf.svg');

const useStyles = makeStyles((theme) => ({
  footer: {
    padding:"1.5rem 1rem",
    color: "white",
    backgroundColor: theme.palette.background.banner,
    display:"flex",
    justifyContent:"space-between",
    flexDirection:"column",
    alignItems:"center",

    [theme.breakpoints.up('sm')]: {
      padding:"1.5rem 15vw",
      flexDirection: 'row',
    },
    '&.transparent':{
      background: "linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%)",
    },
  },
  logo:{
    width:"4rem",
    height:"auto"
  },
  list:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"center",
    [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    },
  },
  listElement:{
    justifyContent:"center",
    [theme.breakpoints.up('sm')]: {
    justifyContent:"flex-start",
    },

  },
  link:{
    textDecoration:"none",
    color:"white",
    fontWeight:"bold",
    fontSize:"0.875rem",
    letterSpacing:"0.02rem",
    textTransform:"uppercase",
    position:"relative",
    transition:"all 0.3s",
      marginBottom:"0.75rem",
      whiteSpace:"nowrap",

    [theme.breakpoints.up('sm')]: {
    marginBottom:"0"
    },

    '&::before':{
      content:`''`,
      position:'absolute',
      width:"100%",
      height:"2px",
      left:"0",
      right:"0",
      bottom:"-4px",
      opacity:"0",
      transform:"translateY(100%)",
      backgroundColor:theme.palette.secondary.main,
      boxShadow: "0px 0px 10px #16E098",
      transition:"inherit",
    },
    '&:hover':{
    color:theme.palette.secondary.main,
      '&::before':{
      transform:"translateY(0%)",
      opacity:"1"
    },
    },
  }
}));

function Footer(props) {
  const { language, routes, loadRoutes, transparent} = props;
  const classes = useStyles();

  useEffect(() => {
    if (!(routes && language in routes)) {
      loadRoutes(language);
    }
  }, [language]);

  return (
    <footer className={`${classes.footer} ${transparent ? 'transparent': ''}`}>

    <List className={classes.list}>
      {routes && language in routes && routes[language]
        .filter((route) =>
          route.menus.filter((menu) =>
            menu.name === 'Footer')
          .length)
        .map((route) => (
        <ListItem className={classes.listElement} key={route.pageType}>
          <Link className={classes.link} to={route.path}>
            {route.label}
          </Link>
        </ListItem>))
      }
      </List>
      <a target="blank" href="https://www.healthcarefuturists.com/"><img className={classes.logo} src={logo} alt="logo" height="96px" /></a>

    </footer>
  );
}

const mapDispatchToProps = dispatch => ({
  loadRoutes: (language) => dispatch(fetchRoutes(language)),
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    routes: state.routes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
