import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar } from '@material-ui/core';

import RibbonLinks from './RibbonLinks';
import RibbonMenu from './RibbonMenu';
import classNames from "classnames";

import { useTheme } from '@material-ui/core/styles';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  links: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bar:{
    paddingTop:"0.75rem",
    paddingBottom:"0.75rem",
    alignItems:"center",
    transition:"all 0.3s",

      [theme.breakpoints.up('md')]: {
        paddingTop:"1.5rem",
        paddingBottom:"0",
        alignItems:"flex-start",
      }


  },
  appBar:{
    position:"fixed",
    top:"0",
    left:"0",
    background:"transparent",

    '&.transparent':{
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%)",
    },

    '&.stuck':{
    transition:"all 0.3s",

    '&.white':{
          boxShadow:"0 2px 8px rgba(0,0,0,0.2)",
    },

    '&>div':{
      alignItems:"center",
      paddingTop:"0.5rem",
      paddingBottom:"0.5rem",
      [theme.breakpoints.up('md')]: {
        alignItems:"center",
        paddingTop:"1rem",
        paddingBottom:"1rem",
      },
    },

        '& img':{
          width:"5vw",
        },
    },

    '&.white':{
    backgroundColor:"#fff",
    }
  },

  brand:{
    display:"flex",
    '& img':{
    width:"20vw",
    transition:"all 0.3s",
    maxWidth:"160px",
    height:"auto",
    minWidth:"120px"
    }
  },
  menu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function Header({adjustOnScroll,transparent}) {
  const classes = useStyles();
  const theme = useTheme();
  const appBarRef = useRef(null);
  const logoRef = useRef(null);
  const toolBarRef = useRef(null);

  const logo =
    theme.palette.type === 'light'
      ? require('../../assets/img/logo-mkm-dark.svg')
      : require('../../assets/img/logo-mkm.svg');

  useEffect(() => {


    gsap.timeline(ScrollTrigger.create({
      trigger: appBarRef.current,
      start: "2",
      end:9999999999,
      toggleClass: "stuck",
      scrub:false,
    }));
  });


  return (
    <AppBar className={`${classes.appBar} ${transparent ? 'transparent': ''} ${adjustOnScroll ? 'white': ''}`} ref={appBarRef}backgroundColor="transparent" elevation={0}>
      <Toolbar ref={toolBarRef} className={classes.bar}>
        <Box flexGrow="1" padding="0">
          <Link to="/" className={classes.brand} >
            <img ref={logoRef} src={logo} alt="logo"/>
          </Link>
        </Box>
        <div className={classes.links}>
          <RibbonLinks />
        </div>
        <div className={classes.menu}>
          <RibbonMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
