import React, { useContext, useEffect } from 'react';

import { connect } from "react-redux";
import { fetchHeader } from '../../redux/actions/headerActions';
import { fetchSections } from '../../redux/actions/sectionActions';
import classNames from "classnames";

import Theme from '../../themes/Theme';
import localTheme from '../../themes/blueTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {Container,Box } from '@material-ui/core';

import Ribbon from '../../components/Ribbon/Ribbon';
import PageIndicator from '../../components/PageIndicator/PageIndicator';
import Teaser from '../../views/HomePage/Sections/TeaserSection';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({

root:{
  background:theme.palette.background.default,
  overflow:"hidden",
},

imgBG:{
  height:"80vh",
  maxHeight:"600px",
  width:"calc(100vw + 2rem)",
  backgroundSize:"cover",
  backgroundRepeat:"no-repeat",
  position:"relative",
  left:"-1rem",
  backgroundPosition:"center",

  "&:before":{
    content:`''`,
    width:"100%",
    height:"25%",
    minHeight:"300px",
    position:"absolute",
    left:"0",
    bottom:"0",
    background:" linear-gradient(180deg, rgba(0, 49, 74, 0) 69.79%, #00314A 100%)",
  }
},

small:{
  height:"30vh",
  maxHeight:"300px",
},

rollOver:{
  height:"30vh",
  maxHeight:"300px",
  backgroundPosition:"center",
  backgroundSize:"100% auto",
  "&:before":{
    display:"none",
  }
},

sections:{
  margin:"3rem 0",
  '&:first-of-type':{
    marginTop:"0",
    '& > div':{
      marginTop:"-2rem",
    }
  },
'&>img':{
  width:"100%",
  height:"auto",
  margin:"0 auto",
  position:"relative",
}
}


}));

const pageType = "Landing";

function MobilePage(props) {
  const { language, header, loadHeader, sections, loadSections } = props;
  const classes = useStyles();
  const [theme, setTheme] = useContext(Theme);

  useEffect(() => {
    if (theme.name !== localTheme.name) {
      setTheme(localTheme);
    }
  }, [theme.name, setTheme]);

  useEffect(() => {
    if (!(header && language in header)) {
      loadHeader(language);
    }
    if (!(sections && language in sections )) {
      loadSections(language);
    }
  }, [language]);


  return (
    <ThemeProvider theme={theme}>
    <Box className={classes.root}>
      <Ribbon transparent adjustOnScroll={false}/>

      <Container>

      {sections && language in sections && sections[language].map((section, i) => (
        <section className={classes.sections}>
        {i==0 &&
        <div className={classes.imgBG} style={{backgroundImage:'url('+section.image.url+')'}} ></div>}
        {i==5 &&
        <div className={classNames(classes.imgBG, classes.rollOver)} style={{backgroundImage:'url('+section.image.url+')'}} ></div>}
        {i==7 &&
        <div className={classNames(classes.imgBG, classes.small)} style={{backgroundImage:'url('+section.image.url+')'}} ></div>}
        {(section.image && i !==0 && i !==7 && i !==5) &&
        <img src={section.image.url} alt={section.subline}/>}
          <Teaser teaser={{...section, order:i}}/>
        </section>
      ))}
      </Container>
      </Box>
      <Footer/>

    </ThemeProvider>
  );
}

const mapDispatchToProps = dispatch => ({
  loadSections: (language) => dispatch(fetchSections(pageType, language)),
  loadHeader: (language) => dispatch(fetchHeader(pageType, language))
})

const mapStateToProps = state => {
  return {
    language: state.language.locale,
    header: state.header[pageType],
    sections: state.sections[pageType],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobilePage);
