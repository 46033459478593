import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Dot from '../../assets/img/dot.svg';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  titel:{
    fontWeight:"bold",
    marginBottom:"0rem",
    fontSize:"1.125rem",
  },
  popOverContainer:{
    position:"absolute",
    left:({ x }) => `${x}`,
    top: ({ y }) => `${y}`,
  },
  paper: {
    padding: "1rem",
    backgroundColor:theme.palette.secondary.main,
    color:"white",
    borderRadius:"8px",
    textAlign:"center",
    width:"100%",
    maxWidth:"320px",
  },
  text:{
    fontSize:"1rem",
    lineHeight:"1.2"
  }
}));

export default function MouseOverPopover({title, text,x,y}) {
  const classes = useStyles({ x, y });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.popOverContainer}>
      <img src={Dot}
        alt="Feature"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}/>
      <Popover
        disableScrollLock={true}
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="h5" className={classes.titel}>{title}</Typography>
        <Typography variant="p" className={classes.text}>{text}</Typography>
      </Popover>
    </div>
  );
}
